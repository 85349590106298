@dl-font-size-base:		14px;
@dl-line-height-base:		1.428571429;
@dl-line-height-computed:	floor((@dl-font-size-base * @dl-line-height-base));
@dl-orange:			#ffb223;


.nav > li > form > a:focus, .nav > li > form > a:hover {
	text-decoration: none;
	background-color: transparent;
}
.navbar-nav > li > form > a {
	line-height: @dl-line-height-computed;
	display: block;
}
@media (max-width: 768px) {
	.nav > li > form > a {
		padding: 10px 15px;
	}
}

#header {
	.nav.navbar-right {
		li form a:hover,
		li form a:active,
		li form a:focus {
			background-color: transparent;
		}
		form a {
			color: @dl-orange;
			text-transform: uppercase;
			font-size: 1.6em;
			text-align: right;
			transition: color .3s;
		}
		li form a:hover { color: white; }
		li.active {
			form a {
				color: white;
			}
		}
	}
}
/* Homepage styles */

.panelpadding {
    padding: 44px 0px;
}

.panel {
    margin: 0px;
    border: 0px solid transparent;
    border-radius: 0px;
    box-shadow: none;
}

.fatheader {
    font-size: 3.3em;
    font-weight: 600;
}

#slideshow {
    overflow: hidden;

    .slide {
        width: 100%;

        &.isalink:hover {
            cursor: pointer;
        }

        .slidebanner {
            width: 100%;
            height: 580px;
            position: relative;
            background-size: cover;
            background-position: center top;

            .overlay {
                position: absolute;
                width: 100%;
                left: 0px;
                bottom: 0px;
                background: rgba(162, 1, 255, 0.55);
                color: white;
                text-align: center;
                padding: 40px 20%;
                h1 {
                    font-size: 2.3em;
                    line-height: 1.5em;
                }
            }
        }

        &.infographic {
            .slidebanner {
                /* for infographic style slides */
                &.purple {
                    background-color: @purple;
                }
                &.red {
                    background-color: @logored;
                }
                &.orange {
                    background-color: @logoorange;
                }
            }
            color: white;

            h1 {
                text-align: center;
                font-size: 4em;
                font-weight: 400;
                margin: 60px 0px 60px;
            }
            .blurb {
                font-size: 1.5em;
            }

            .column {
                padding: 0px 20px;
                img {
                    max-width: 90%;
                    margin: 0 auto;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #slideshow {
        .slide {
            .slidebanner {
                height: 400px;
                position: relative;
                background-size: cover;
                background-position: center top;

                .overlay {
                    background: rgba(162, 1, 255, 0.55);
                    color: white;
                    text-align: center;
                    padding: 20px 10%;
                    h1 {
                        font-size: 1.7em;
                        line-height: 1.5em;
                    }
                }
            }

            &.infographic {
                .slidebanner {
                    /* for infographic style slides */
                    &.purple {
                        background-color: @purple;
                    }
                    &.red {
                        background-color: @logored;
                    }
                    &.orange {
                        background-color: @logoorange;
                    }
                }
                color: white;

                h1 {
                    text-align: center;
                    font-size: 2em;
                    font-weight: 400;
                    margin: 30px 0px 20px;
                }
                .blurb {
                    font-size: 1em;
                    text-align: center;
                    padding: 10px 40px;
                }

                .column {
                    padding: 10px 20px;
                    width: 100%;
                    img {
                        display: block;
                        max-width: 50%;
                        margin: 0 auto;
                        max-width: 300px;
                    }
                }
            }
        }
    }
}

#slidecontrols {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 22px;
    padding: 5px;
    background-color: white;
    text-align: center;
    box-shadow: 0px -5px 5px 5px #888888;

    .bullets {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;

        .bullet {
            height: 14px;
            width: 14px;
            border-radius: 10px;
            background-color: #3d3d3d;
            margin: 4px;

            &.cycle-pager-active {
                background-color: #a1a1a1;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.clearbutton {
    background-color: transparent;
    border-radius: none;
    border: 3px solid @logored;
    text-transform: uppercase;
    color: @logored;
    letter-spacing: 0.07em;
    font-size: 1.4em;
    font-weight: 600;
    padding: 8px 18px;
    transition: all 0.3s;

    &:hover {
        text-decoration: none;
        background-color: @logored;
        color: white;
    }
}

#ourwork {
    .top {
        text-align: center;
        padding-top: 12px;

        h1 {
            color: @logored;
            .fatheader;
        }
        p {
            font-size: 1.5em;
            max-width: 500px;
            margin: 0 auto;
        }

        padding-bottom: 32px;
    }
    .three {
        text-align: center;
        padding: 0px 42px;

        h2 {
            font-weight: 600;
            font-size: 1.6em;
            padding-bottom: 12px;
            border-bottom: 3px solid @logored;
            margin-bottom: 12px;
        }
        p {
            font-size: 1.2em;
            margin-bottom: 32px;
        }

        a {
            .clearbutton;
        }
        margin-bottom: 44px;
    }
    padding-bottom: 44px;
}

#newsletter {
    .panelpadding;
    background-color: @lemon;
    .col-md-6 {
        margin-bottom: 22px;
    }

    h2 {
        margin: 0px 0px 22px 0px;
        text-transform: uppercase;
        font-size: 1.7em;
        letter-spacing: 0.04em;
    }
    .newsletter {
        button,
        input {
            padding: 10px 14px;
            font-size: 1.7em;
            letter-spacing: 0.04em;
            border: 0px solid transparent;
            margin-right: 0px;
            width: 56%;

            &:focus {
                border: none;
                box-shadow: none;
            }
        }
        button {
            width: 34%;
            text-transform: uppercase;
            background-color: darken(#f3e056, 10%);
            border: 0px solid transparent;
            position: relative;
            left: -6px;
        }
    }
    .social {
        a {
            display: block;
            width: 54px;
            float: left;
            margin-right: 20px;
            img {
                width: 100%;
            }
        }
    }
}

#news {
    padding-top: 62px;

    h1 {
        text-align: center;
        .fatheader;
        margin: 0px 0px 62px;
    }
}

.mobile-image {
    width: 100%;
    img {
        max-width: 100%;
        margin-bottom: 22px;
    }
}

.mobile-meta {
    width: 100%;
    border: 1px solid #d6c3a7;
    border-width: 1px 0px;
    margin-bottom: 22px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span {
        width: 40%;
    }
    h5.share-bar {
        width: 60%;
    }
    h5,
    span {
        font-size: 0.8em;
    }

    h5.share-bar {
        text-align: right;
        a img {
            margin-top: -2px;
            width: 20px;
        }
    }
}

@media screen and (min-width: 768px) {
    .mobile-meta,
    .mobile-image {
        display: none;
    }
    .desktop-meta {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .news.post .sidebar {
        display: none;
    }
    .mobile-meta {
        display: flex;
    }
    .desktop-meta {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    #newsletter .newsletter {
        form {
            input,
            button {
                width: 100%;
                left: 0;
            }
        }
    }
}

/* About page styles */

article.about.page {
    header {
        .fatstripe {
            padding: 38px 12px;
            background-color: @logored;
            color: white;
            text-align: center;
        }

        .banner {
            width: 100%;
            height: 250px;
            background-size: cover;
            position: relative;
            background-position: center;

            .stripe {
                //margin-top: 148px;
                float: left;
                position: absolute;
                bottom: 0;
                width: 100%;
                background-color: rgba(253, 76, 36, 0.83);
            }
        }
    }
}

.about.page {
    .content {
        h1 {
            font-size: 1.5em;
            padding-bottom: 7px;
            margin: 22px 0px;
            border-bottom: 1px solid #c9c9c9;
        }
    }
}

ul.nav.nav-stacked.nav-pills.about {
    font-size: 16px;
    border-top: 1px #fd4c24 solid;
    border-bottom: 1px #fd4c24 solid;
    margin-bottom: 15px;
}

/* Events and Programs Index Pages */

article.indexpage.events-index,
article.indexpage.program-index {
    .program.row,
    .event.row {
        position: relative;
        background-color: @tan;
        border-bottom: 3px solid #e6d6a3;
        margin: 0px 33px 66px 33px;

        .program-thumbnail,
        .event-thumbnail {
            height: 100%;
            background-size: cover;
            min-height: 240px;
        }

        .program-content h2 {
            padding: 0px;
            margin: 0px 0px 14px;
        }

        .program-content,
        .event-content {
            padding: 26px 33px;

            h2 a {
                color: black;

                &:hover {
                    text-decoration: none;
                }
            }

            h4 {
                color: @pumpkin;
                display: inline-block;
                padding: 12px 36px 6px 0px;

                .icon {
                    width: 20px;
                    margin-right: 10px;
                    position: relative;
                    top: -2px;
                }
                &.location {
                    .icon {
                        margin-right: 4px;
                    }
                }
            }

            .excerpt {
                padding-right: 66px;
            }
        }
    }
}

@media screen and (max-width: 990px) {
    article.indexpage.events-index,
    article.indexpage.program-index {
        .program.row,
        .event.row {
            margin: 0px 18px 40px 18px;
            .program-thumbnail,
            .event-thumbnail {
            }
            .program-content h2 {
            }
            .program-content,
            .event-content {
                h2 a {
                    font-size: 0.8em;
                    &:hover {
                    }
                }
                h4 {
                    .icon {
                        width: 20px;
                        margin-right: 10px;
                        position: relative;
                        top: -2px;
                    }
                    &.location {
                        .icon {
                            margin-right: 4px;
                        }
                    }
                }
                .excerpt {
                    padding-right: 0px;
                    padding-bottom: 28px;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    article.indexpage.events-index,
    article.indexpage.program-index {
        .program.row,
        .event.row {
            margin: 0px 0px 30px 0px;
        }
    }

    article.indexpage.events-index .event.row .event-content h4 {
        &.location,
        &.time {
            width: 100%;
        }
        &.time {
            margin-top: 3px;
            padding-bottom: 0px;
        }
        &.location {
            margin-top: 0px;
            padding-top: 0px;

            .icon {
                margin-right: 9px;
            }
        }
    }
}

.partner-logo {
    width: 20%;
    margin-right: 5%;
    float: left;
    box-shadow: 1px 1px 1px gray;
}

/* Events page */

.registrationlink {
    display: block;
    background-color: @logored;
    color: white;
    padding: 10px;
    letter-spacing: 1px;
    font-size: 1.2em;
    text-align: center;
    width: 100%;
    border: 12px solid @tan;
    text-decoration: none;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:visited {
        text-decoration: none;
        color: white;
        background-color: darken(@logored, 10%);
    }
}

.map {
    height: 350px;
    overflow: hidden;
}

.row.buttonrow {
    text-align: center;
}

.event.page.twocol {
    header .ribbon {
        background-color: @logoorange;
    }
}

.program.block {
    a {
        color: @purple;
    }
}

@media screen and (max-width: 768px) {
    .hide-mobile {
        display: none;
    }
    article.page.twocol {
        header {
            margin-bottom: 30px;
        }
    }
    .eventpage .sidebar,
    .resource .sidebar {
        display: none;
    }
    .mobile-sidebar {
        display: block;
        margin-top: 30px;
    }
    .map {
        display: none;
    }
    article.page.twocol .content .main h1.title {
        font-size: 1.6em;
        margin-bottom: 16px;
    }
    .resource-download.block.group {
        max-width: 260px;
    }
}

@media screen and (min-width: 768px) {
    .mobile-sidebar {
        display: none;
    }
}

/*  Program page styles  */

.at-a-glance {
    font-size: 90%;

    ul {
        padding-left: 28px;
    }
}

.section-header {
    margin: 38px 4%;
}

.news.indexbody.container-fluid,
.partners-grid {
    padding-left: 4%;
    padding-right: 4%;
}

.partners-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: left;
    justify-content: left;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        height: 120px;
        padding: 12px;

        img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            margin: auto;
        }
    }
}

@media screen and (max-width: 768px) {
    .page.twocol.program {
        header {
            margin-bottom: 0px;
        }
        .sidebar {
            padding-left: 0px;
            padding-right: 0px;

            .sidebar-inner {
                margin-bottom: 25px;

                .ribbon {
                    margin-top: 0px;
                    text-align: center;
                }
            }
        }
        .body {
            padding: 0px 15px;
        }
    }
}

/*  Resources page styles  */

.resources-index {
    .tabs {
        width: 100%;
        height: 100px;
        position: relative;
        overflow: hidden;

        .tab-shadow,
        .tab-wrapper {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
        }

        .tab-shadow {
            box-shadow: inset 0px -10px 15px rgba(205, 205, 205, 0.66);
            z-index: 100;
        }

        .tab-wrapper {
            top: 5px;
            z-index: 200;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            flex-direction: row;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
            padding-top: 42px;
        }

        .tab {
            margin: 0px 5px;
            padding: 0px;
            box-shadow: 0 -2px 25px #c3c1c1;
            border-radius: 5px 5px 0px 0px;

            a {
                background-color: white;
                color: #728807;
                display: inline-block;
                padding: 12px 14px 10px 14px;
                // border: 1px solid black;
                box-shadow: inset 0 -8px 10px -4px rgba(0, 0, 0, 0.2);
                border-radius: 5px 5px 0px 0px;
                border-width: 1px 1px 0px 1px;
                text-transform: uppercase;

                &:hover {
                    text-decoration: none;
                }
                h3 {
                    font-weight: 400;
                    padding: 0px;
                    margin: 0px;
                }
            }

            &.current {
                // box-shadow: inset 0 -8px 15px -6px rgba(0,0,0,0.3);
                box-shadow: 0 -5px 9px -2px rgba(0, 0, 0, 0.25);
                border-bottom: 0px solid white;
                a {
                    box-shadow: none;
                }
            }
        }
    }
}

.resource.video {
    width: 100%;
    margin-bottom: 22px;

    iframe {
        width: 100%;
    }
}

.tab-burger {
    display: none;
}

@media screen and (max-width: 800px) {
    .resources-index {
        .tabs {
            .tab-shadow {
                display: none;
            }
            height: auto;
            .tab-wrapper {
                position: relative;
                flex-direction: column;
                -webkit-flex-direction: column;
                -moz-flex-direction: column;
                padding-top: 12px;
            }
            .tab-burger {
                display: block;
                position: absolute;
                // z-index: 300;
                top: 16px;
                right: 16px;

                .icon-bar {
                    display: block;
                    background-color: @green;
                    width: 22px;
                    height: 2px;
                    margin-bottom: 4px;
                    border-radius: 1px;
                }
            }
            .tab {
                width: 100%;
                float: left;
                border-radius: 0px;
                box-shadow: 0 -2px 25px #c3c1c1;
                position: relative;

                &.current {
                    position: relative;
                    a {
                        // background-color: #F9FEDC;
                    }
                    .tab-burger {
                        opacity: 1;
                    }
                }
                &.notcurrent {
                    .tab-burger {
                        opacity: 0;
                    }
                }

                a {
                    box-shadow: none;
                    width: 100%;
                    text-align: center;
                    border-radius: 0px;
                }
            }
            &.collapsed {
                .tab.notcurrent {
                    display: none;
                }
            }
            &.expanded {
                .tab.notcurrent {
                    display: block;
                }
            }
        }
    }
}

.resources.two-up-grid {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 4%;

    .resource {
        position: relative;
        background-color: @avocado;
        width: 46%;
        margin-right: 4%;
        padding: 28px 28px 12px 28px;
        margin-bottom: 44px;

        .ribbon {
            background-color: @green;
            text-transform: uppercase;
            color: white;
            position: absolute;
            top: 12px;
            left: -10px;
            padding: 7px 12px 6px 20px;

            h4 {
                margin: 0px;
                letter-spacing: 0.5px;
            }
        }
        .shadow {
            position: absolute;
            left: -10px;
            width: 10px;
            top: 43px;
        }

        img.thumb {
            width: 30%;
            margin-right: 5%;
            margin-bottom: 12px;
            float: left;
        }
        img.resource-default-thumb {
            padding-top: 14px;
            padding-bottom: 18px;
        }
        h2 {
            line-height: 0.85em;
        }
        h2 a {
            color: black;
            font-size: 0.7em;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .resources.two-up-grid {
        .resource {
            width: 100%;
        }
    }
}

/*  Resource page styles  */

.resource {
    .sidebar {
        .thumb {
            float: left;
            width: 45%;
            margin-right: 5%;
        }
        .resource-thumbnail,
        .resource-download {
            width: 100%;
            a {
                display: inline-block;
                width: 50%;
                padding-top: 33px;
                color: black;
                text-align: center;

                span {
                    display: inline-block;
                    width: 100%;
                }

                &:hover {
                    text-decoration: none;

                    img {
                        opacity: 1;
                    }
                }

                img {
                    padding-top: 6px;
                    width: 40%;
                    opacity: 0.8;
                }
            }
            &.external {
                a.imagelink {
                    padding-top: 0px;
                    width: 50%;
                    margin: 0px auto;
                    float: left;

                    img {
                        width: 100%;
                        padding: 7px;
                        border: 1px solid #93ae0f;
                    }
                }
                .externalnote {
                    display: inline-block;
                    margin-top: 30px;
                    text-align: center;
                    padding: 10px;
                    width: 50%;
                }
            }
        }
    }
}

/* virtual event sidebar styles */
.eventpage {
    .resource-download,
    .resource-thumbnail {
    }
    .doc-thumb {
        float: left;
        width: 45%;
        margin-right: 5%;
    }
    .resource-thumbnail,
    .resource-download {
        width: 100%;

        a {
            display: inline-block;
            width: 50%;
            padding-top: 33px;
            color: black;
            text-align: center;

            span {
                display: inline-block;
                width: 100%;
            }

            &:hover {
                text-decoration: none;

                img {
                    opacity: 1;
                }
            }

            img {
                padding-top: 6px;
                width: 40%;
                opacity: 0.8;
            }
        }
    }
}

/* Pagination styles */

.pagination {
    width: 100%;
    padding: 12px 0px;
    text-align: center;

    a {
        font-size: 1.5em;
        background-color: transparent;
        padding: 4px 12px;
        margin-right: 12px;
        &:last-of-type {
            margin-right: 0px;
        }
        border: 2px solid @green;
        color: darken(@green, 10%);

        &:hover {
            text-decoration: none;
            background-color: lighten(@avocado, 10%);
        }

        &.currentpage {
            background-color: @avocado;
        }
    }
}

/* Staff / Board page styles */

article.people {
    .main h1 {
        margin-bottom: 33px;
    }
    .person.row {
        margin-bottom: 26px;

        .profile.thumbnail {
            width: 100%;
            padding: 0px;
            border: none;
            border-radius: 0px;
        }
        .bio {
            padding-left: 26px;
            padding-top: 0px;

            h2 {
                color: darken(@logoorange, 10%);
                font-size: 1.5em;
                margin: 0px;
                padding: 0px 0px 8px 0px;
            }
            h4 {
                margin-top: 0px;
                font-weight: 600;
            }
            .organization {
                margin-top: -8px;
            }
        }
        .bio.nopad {
            padding-left: 16px;
        }
    }
}

@media screen and (max-width: 768px) {
    .square.thumbnail-image {
        max-width: 200px;
    }
    article.people .person.row .bio {
        padding: 22px 18px 0px;
    }
    article.people .person.row .bio.nopad {
        padding-top: 0px;
    }
}

/* Contact form and page styles */

.flatbutton {
    background-color: @logored;
    border: none;
    color: white;
    padding: 6px;
}

.contact.sidebar {
    background-color: @tan;
    padding: 22px;

    h3 {
        font-size: 1.25em;
        font-weight: 500;
        margin: 0px 12px 22px;
        text-align: center;
    }
    form {
        font-size: 1.25em;
        font-weight: 500;
        input {
            text-align: center;
            border: none;
            padding: 6px;
            margin-bottom: 12px;
        }
        button {
            .flatbutton;
        }
    }
}

.page {
    .contact-info-col,
    .contact-col {
        padding: 0px 22px 32px;
    }
}
.contact-form {
    button {
        .clearbutton;
        float: right;
        padding: 6px 22px;

        &:hover {
            border: 3px solid #fd4c24;
        }
    }
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
    background-color: transparent !important;
}
.navbar-nav > li > .dropdown-menu {
    z-index: 999 !important;
}
.navbar-nav > li > .dropdown-menu a {
    text-align: left !important;
    color: #ffb223 !important;
    padding: 5px 15px;
}
.navbar-nav > li > .dropdown-menu a:hover {
    color: #fd4c24 !important;
}

@media screen and (max-width: 768px) {
    .navbar-nav > li > .dropdown-menu {
        float: none;
        position: relative;
        border-radius: 0;
        display: block;
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: 0;
    }

    .navbar-nav > li > .dropdown-menu a {
        text-align: right !important;
        padding: 10px 15px;
        line-height: 1;
    }
}

/* Registration Page */

#form-register {
    p {
        font-size: 18px;
    }

    .row .row > * {
        margin-top: 0;
        padding: 0;

        &:last-of-type {
            border-left: 0;
        }
    }
}
.navbar-nav > li > .dropdown-menu {
    z-index: 999 !important;
}
.navbar-nav > li > .dropdown-menu a {
    text-align: left !important;
    color: #ffb223 !important;
    padding: 5px 15px;
}
.navbar-nav > li > .dropdown-menu a:hover {
    color: #fd4c24 !important;
}

@media screen and (max-width: 768px) {
    .navbar-nav > li > .dropdown-menu {
        float: none;
        position: relative;
        border-radius: 0;
        display: block;
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: 0;
    }

    .navbar-nav > li > .dropdown-menu a {
        text-align: right !important;
        padding: 10px 15px;
        line-height: 1;
    }
}

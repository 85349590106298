@font-face {
    font-family: 'missiongothic';
    src: url('../../fonts/missiongothic/mission_gothic_bold-webfont.eot');
    src: url('../../fonts/missiongothic/mission_gothic_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/missiongothic/mission_gothic_bold-webfont.woff2') format('woff2'),
         url('../../fonts/missiongothic/mission_gothic_bold-webfont.woff') format('woff'),
         url('../../fonts/missiongothic/mission_gothic_bold-webfont.ttf') format('truetype'),
         url('../../fonts/missiongothic/mission_gothic_bold-webfont.svg#mission_gothicbold') format('svg');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'missiongothic';
    src: url('../../fonts/missiongothic/mission_gothic_light-webfont.eot');
    src: url('../../fonts/missiongothic/mission_gothic_light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/missiongothic/mission_gothic_light-webfont.woff2') format('woff2'),
         url('../../fonts/missiongothic/mission_gothic_light-webfont.woff') format('woff'),
         url('../../fonts/missiongothic/mission_gothic_light-webfont.ttf') format('truetype'),
         url('../../fonts/missiongothic/mission_gothic_light-webfont.svg#mission_gothiclight') format('svg');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'missiongothic';
    src: url('../../fonts/missiongothic/mission_gothic_regular_italic-webfont.eot');
    src: url('../../fonts/missiongothic/mission_gothic_regular_italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/missiongothic/mission_gothic_regular_italic-webfont.woff2') format('woff2'),
         url('../../fonts/missiongothic/mission_gothic_regular_italic-webfont.woff') format('woff'),
         url('../../fonts/missiongothic/mission_gothic_regular_italic-webfont.ttf') format('truetype'),
         url('../../fonts/missiongothic/mission_gothic_regular_italic-webfont.svg#mission_gothicregular_italic') format('svg');
    font-weight: 500;
    font-style: italic;

}

@font-face {
    font-family: 'missiongothic';
    src: url('../../fonts/missiongothic/mission_gothic_regular-webfont.eot');
    src: url('../../fonts/missiongothic/mission_gothic_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/missiongothic/mission_gothic_regular-webfont.woff2') format('woff2'),
         url('../../fonts/missiongothic/mission_gothic_regular-webfont.woff') format('woff'),
         url('../../fonts/missiongothic/mission_gothic_regular-webfont.ttf') format('truetype'),
         url('../../fonts/missiongothic/mission_gothic_regular-webfont.svg#mission_gothicregular') format('svg');
    font-weight: 500;
    font-style: normal;

}

/* Sitewide and layout styles */


/* utilities */

.zero,
.spacereset {
	margin: 0px;
	padding: 0px;
}

.padded-medium {
	padding: 26px 0px;
}

.padded-fat {
	padding: 33px 0px;
}

.form-full {
	input, button, textarea {
		width: 100%;
	}
}

.square {
	/*  use this class for responsive squares 
		height proportional to width
		put in a responsive container
		DON'T declare height on this element
	*/
	width: 100%;
}

.square:after {
	content: "";
	display: block;
	padding-bottom: 100%;
	/*  pseudo-element padding calculated as percentage of parent width
		so this fixes the height of the pseudo-el to the width of .square
	*/
}

.square.thumbnail-image,
.rect.thumbnail-image {
	background-size: cover;
	background-position: 50%;
}

.rect {
	width: 100%;
}

.rect:after {
	content: "";
	display: block;
	padding-bottom: 80%;
	/*  pseudo-element padding calculated as percentage of parent width
		so this fixes the height of the pseudo-el to the width of .square
	*/
}


/* little inline social icon fellas */
span.social {
	img {
		width: 30px;
		margin-right: 3px;
		position: relative;
		top: -3px;
	}
	a {
		font-weight: 500;
	}
	margin-right: 8px;
}


.share-bar {
  display: inline-block;
  text-align: left;
  width: 100%;
  overflow: display;

  a:hover {
    text-decoration: none;
  }
  a:first-child {
	padding-left: 12px;
  }

  img {
    width: 40px;
    position: relative;
    margin-top: -6px;
    margin-left: 6px;
  }

}


.maps iframe {
	.zero;
}


/* Some type styles */


h1, h2, h3 {
	&.bold {
		font-weight: 600;
	}
}

h1, h2, h3 {
	&.underlined {
		padding-bottom: 7px;
		border-bottom: 3px solid @logored; 
	}
}

/* Header */

#header {
	.dark;	
	width: 100%;
	padding: 12px;
	.navbar {
		margin-bottom: 2px;
	}

	.navbar-header {
		a {
			display: block;
			padding: 0px;
			margin: 0px;
		}
		img.logo {
			max-width: 234px;
			width: 100%;
		}
	}
	.nav.navbar-right {
		padding-right: 22px;
		li:hover,
		li a:hover,
		li:active,
		li a:active,
		li:focus,
		li a:focus {
			background-color: transparent;
		}
		a {
			color: @logoorange;
			text-transform: uppercase;
			font-size: 1.6em;
			text-align: right;
			transition: color .3s;
		}
		li a:hover { color: white; }
		li.active {
			a {
				color: white;
			}
		}
	}
	.navbar-collapse {
		box-shadow: none;
	}	 
}

.navbar-toggle .icon-bar {
	background-color: @logoorange;
}

@media (min-width: 768px) {
	.navbar-right {
		position: absolute;
	    right: 0px;
	    bottom: 6px;
		li a {
			padding: 2px 2px 2px 22px;    	
		}
	 }
	.mobile-logo {
		display: none;
	}  
}

@media (max-width: 768px) {

	#header {
		padding: 7px 0px;

		.container-fluid {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.mobile-logo {
		display: block;
	}
	.nonmobile-logo {
		display: none;
	}
	#header .searchbar {
		display: none;
	}

}

@media (min-width: 768px) {
	
	.container-fluid {
		padding-left: 25px;
		padding-right: 25px;
	}

}


.navbar-toggle {
	margin-right: 0px;
	margin-top: 28px;
	margin-bottom: 0px;
}

/* Basic page styles */

article.page {
	header {
		.stripe {
			padding: 26px;

			h1 {
				color: white;
				text-align: center;
				font-size: 3.3em;
				.spacereset;
			}
		}

		h1 {
			font-size: 3.3em;
			text-transform: capitalize;
		}

		margin-bottom: 70px;
	}
	.main {
		h1 {
			font-size: 2.5em;
		}
		.body {
			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
	.content {
		font-size: 18px;
		font-weight: 500;
	}
}

@media screen and (max-width: 768px) {

	article.page {
		header {
			margin-bottom: 30px;
		}
	}
	
}




/* Index pages */

article.indexpage {

	header {
		.banner {
			width: 100%;
			height: 250px;
			background-size: cover;

			.stripe {
				margin-top: 148px;
				float: left;
				width: 100%;				
			}
		}		
		.stripe {
			padding: 26px;

			h1 {
				color: white;
				text-align: center;
				font-size: 3.3em;
				.spacereset;
			}
		}
	}

	&.events-index header .stripe {
		// background-color: @logoorange;
		background-color: rgba(255, 178, 35, 0.88)
	}

	&.program-index header .stripe {
		background-color: @purple;
	}		

	&.resources-index header .stripe {
		background-color: @green;
	}		

	&.news-index header .stripe,
	&.people header .stripe {
		background-color: @logored;
	}

	.indexbody {
		.intro {
			text-align: center;
			padding: 0px 22px;

			h3 {
				font-weight: 500; 
				line-height: 1.4em;
			}
		}
	}

	.more {
		position: absolute;
		right: -10px;
		bottom: 18px;
		background-color: @logored;
		padding: 6px 10px;

		a {
			color: white;
			text-transform: uppercase;
			font-weight: 600;

			&:hover {
				text-decoration: none;
			}
		}
	}

}

.shadow {
	position: absolute;
	width: 10px;
	right: -10px;
	bottom: 10px;
}

@media screen and (max-width: 900px) {

	.indexbody {
		.intro {
			h3 {
				font-size: 1.3em;
			}
		}
	}
	
}

@media screen and (max-width: 600px) { 

	article.indexpage {

		header {

			.banner {
				height: auto;
				background-image: none;
				.stripe {
					margin-top: 0px;
					margin-bottom: 26px;
				}
			}		
			.stripe {
				padding: 26px;

				h1 {
					color: white;
					text-align: center;
					font-size: 2.4em;
					.spacereset;
				}
			}
		}
	}

}


/* Masonry Grid page */

#news.panel .container-fluid,
.news.indexbody.container-fluid {
	max-width: 4000px;
}

.masonry-grid {
	// padding-left: 3%;
	width: 100%;
	left: 16px;

	.grid-sizer {
	}
	.gutter-sizer {
	}
	.grid-sizer, .gutter-sizer {  
		// display: none;
	}

	.grid-item {
		float: left;
		// height: 300px;
		// position: absolute !important;
		// display: inline-block;
		background-color: @tan;
		margin-bottom: 28px;
	}

	.post {
		.ribbon {
			position: absolute;
			display: inline-block;
			text-transform: uppercase;
			color: white;
			top: 12px;
			left: -10px;
			padding: 7px 12px 6px 20px; 

			h4 { margin: 0px; letter-spacing: .5px; display: inline; }

			.shadow {
			    top: 34px;
			    left: 0px;
			}
		}
		&.resource {
			.ribbon {
				background-color: @green;							
			}
		}
		&.update,
		&.press-release {
			.ribbon {
				background-color: @logored;							
			}
		}
		&.program-news {
			.ribbon {
				background-color: @purple;							
			}
		}
		&.event {
			.ribbon {
				background-color: @logoorange;							
			}
		}		
		.rect {
			margin-bottom: -8px;
		}		
		.content {
			padding: 28px 28px 12px 28px;

			&.has-image {
				padding-top: 0px;
			}
			h2 {
				line-height: .85em;
				padding-top: 6px;
			}
			h2 a {
				color: black;
				font-size: .7em;
				&:hover {
					text-decoration: none;
				}
			}
			p {
				font-size: 14px;
				font-weight: 500;
			}			
		}
	}
}

button#showmore {
	// .clearbutton;
	display: block;
	margin: 50px auto;
	padding: 6px 22px;

	&:hover {
		border: 3px solid #fd4c24;
	}	
}




/* view more styles */
.masonry-grid {
	.post.hidden {
		display: none;
	}
	.post.visible {
		display: block;
	}
}

@media screen and (min-width: 920px) {
	.grid-sizer {
		width: 30%;		
	}
	.gutter-sizer {
		width: 3.3%;
	}
	.grid-item {
		width: 30%;
		margin-bottom: 28px;
	}		
}

@media screen and (min-width: 600px) and (max-width: 920px) {
	.grid-sizer {
		width: 46%;		
	}
	.gutter-sizer {
		width: 4%;
	}
	.grid-item {
		width: 46%;
		margin-bottom: 28px;
	}		
}

@media screen and (max-width: 600px) {
	.masonry-grid {
		left: 10px;
	}	
	.grid-sizer {
		width: 96%;		
	}
	.gutter-sizer {
		width: 4%;
	}
	.grid-item {
		width: 96%;
		margin-bottom: 28px;
	}		
}

@media screen and (min-width: 1260px) {
	.grid-sizer {
		width: 22.5%;		
	}
	.gutter-sizer {
		width: 2.5%;
	}
	.grid-item {
		width: 22.5%;
		margin-bottom: 28px;
	}	
}


/* Content pages */

article.page.twocol {
	header {
		.banner {
			width: 100%;
			height: 350px;
			background-size: cover;
		}

		.ribbon {			
			width: 100%;
			background-color: gray;
			color: white;	
			padding: 5px;
			text-align: center;

			h4 {
				.zero;
				letter-spacing: 2px; 
			}		
		}
		.title {
			width: 100%;
			h1 { 
				max-width: 1000px;
				text-align: center;
				display: inline-block;
				margin: 0 auto;
				.zero;
			}			
			padding: 26px;
			background-color: @tan;
			color: black;
			text-align: center;
		}
	}
	.content {
		.sidebar {
			.date.block,
			.location.block {
				span {
					display: block;
				}
				span.inline {
					display: inline;
				}
			}
		}
		.sidebar-inner {
			background-color: @tan;
			padding: 0px;
			position: relative;
			float: left;
			width: 100%;

			.ribbon {
				width: 100%;
				background-color: @logored;			
				color: white;
				text-transform: uppercase;
				margin: 16px 0px;
				padding: 7px;

				h4 {
					margin: 0px;
					letter-spacing: 1px;
				}
			}
			.shadow {
				border-top: 33px solid @logored;
				width: 10px;
				position: absolute;
				top: 16px;
				left: -10px;
			}
			&.programnews {
				.ribbon { background-color: @purple; }
				.shadow { border-top: 33px solid @purple; }
			}				

			.block {
				padding: 0px 16px 12px 16px;
			}
			.block.image {
				padding: 0px;
			}

			.related-program {
				text-transform: uppercase;

				a {
					color: @purple;
				}
			}

			img {
				max-width: 100%;
			}

		}
		.main {
			h1.title {
				margin: 0px 0px 33px 0px;
				line-height: 1.36em;
				font-weight: 600;
			}
		}
	}
}

.subheading {
	margin: 32px 0px 16px 0px;
	padding: 0px 8px 5px 8px;
	border-bottom: 3px solid black;
	&.tan {
		border-bottom: 3px solid #e6d6a3;
	}
	&.green {
		border-bottom: 3px solid #c1cc86;
	}
	&.centered {
		text-align: center;
		border-bottom: 0px solid white;
		margin-bottom: 50px;
	}			
}

article.page.twocol.eventpage header .ribbon {
	background-color: @logoorange;
}

article.page.twocol.program header .ribbon {
	background-color: @purple;
}

article.page.twocol.resource header .ribbon {
	background-color: @green;
}

article.page.twocol.news header .ribbon {
	background-color: @logored;
}



article.page.twocol.resource {
	.sidebar-inner {
		background-color: @avocado;
		.ribbon { background-color: @green; }
		.shadow { border-top: 33px solid @green; }	

			.thumb.full {
				width: 100%;
				margin-right: 0px;
			}			
	}
}

@media screen and (min-width: 1000px) {

	article.page.twocol {
		.sidebar {
			padding: 0px 32px 0px 32px;
		}
		.main {
			.body {
				padding-right: 50px;
			}
		}			
	}

}


@media screen and (max-width: 1000px) {
	article.page header h1 {
		font-size: 2.4em;
	}
}





#footer {
	.dark;

	margin-top: 70px;
	padding: 70px 0px 130px 0px;
	// color: @gray-light;

	.heading {
		color: @logoorange;
	}

	ul {
		list-style-type: none;
		margin: 0px;
		padding: 0px;

		li {
			margin-bottom: 6px;
			line-height: 1em;
		}

		li a {
			color: white;
		} 

		li img {
			width: 100%;
			margin-bottom: 22px;

			&.logo {
				position: relative;
				left: -6px;
				max-width: 200px;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	
	#footer {
		ul h2 {
			font-size: 1.6em;
		}
		img.logo {
			margin-top: 22px;
		}
	}

}

@media screen and (max-width: 990px) {
	#footer img.logo {
		margin-top: 22px;
	}	
}


@media screen and (min-width: 768px) {
	
	#footer {
		ul {
			padding-right: 22px;
		}
	}

}

.gallery-image img {
    display: block;
    max-width: 100%;
    height: auto;
}

.group:after,
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.noresults {
	text-align: center;
	h1 {
		font-weight: 400;
		font-size: 3em;
		color: rgba(0, 0, 0, 0.6);
	}
}


/* Search stuff */

.searchbar,
.searchpage {
	* {
		// box-sizing: content-box;		
	}
}

#header {
	.container-fluid {
		position: relative;
	}

	.searchbar {
		width: 30%;
		position: absolute;
    	top: 0px;
    	right: 22px;
    	z-index: 500;		
	}
    
    .gsc-input {
    	opacity: .6;
    	transition: opacity .5s;
    
		&:hover,
		&:focus {
			opacity: 1;
		}
    }


	#___gcse_0 {
	}

	.gsc-control-cse {
		background-color: transparent;
		border-width: 0px !important;
	}

	.gsc-search-button {
		box-sizing: content-box;
		position: relative;
		top: .5px;
		border: 0px solid transparent;
		border-radius: 0px;				
	}
	.gsc-input-box {
		border: 0px solid transparent;
		overflow: hidden;
	}
	.gsc-input input {
		color: black;
		font-weight: 600;
		font-family: @body;
	}
	.gsc-search-button {

	}
	input.gsc-search-button, input.gsc-search-button:hover, input.gsc-search-button:focus	{
		background-color: #0A0A0A;
		border-color: #0A0A0A;		
		background-color: @logored;
		border-color: @logored;	
	}
	td.gsib_b {
		position: relative;
	    top: 3px;
	}


	.gsc-results-wrapper-overlay.gsc-results-wrapper-visible {

		* {
			box-sizing: content-box;
		}
	}

	.gs-image-box.gs-web-image-box.gs-web-image-box-portrait,
	.gs-image-box.gs-web-image-box.gs-web-image-box-landscape,
	.gs-bidi-start-align.gs-snippet {
		margin-left: 7px;
	}

}














.transparent-menu {

	.container-fluid {
		max-width: 1350px;
	}

	.navbar-collapse {
		&.in {
			overflow: visible;
		}

		#___gcse_1 {
			@media screen and (min-width: 769px) {
				display: none;
			}

			> div {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.socials {
			@media screen and (min-width: 769px) {
				display: none;
			}

			a {
				color: @white;
				display: inline-block;
				margin-right: 15px;
			}
		}
	}

	#header {
		span.x {
			display: none;
			font-size: 28px;
		}

		&.in {
			@media screen and (max-width: 768px) {
				background-color: rgba(0,0,0,0.8);
			}

			span.icon-bar {
				@media screen and (max-width: 768px) {
					display: none;
				}
			}

			span.x {
				display: block;
			}
		}

	//	@media screen and (min-width: 769px) {
			background-color: transparent;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 10;
		//}

		.navbar-toggle {
			@media screen and (max-width: 768px) {
				margin-top: 10px;
			}

			.icon-bar {
				background-color: @white;
				width: 40px;
				height: 3px;
				margin-bottom: 8px;
			}
		}

		.nav.navbar-right li {
			a {
				color: @white;
				text-transform: capitalize;

				font-size: 18px;

				@media screen and (max-width: 768px) {
					text-align: left;
				}
			}
			&.active {
				a span { border-bottom: 3px solid @white; }
			}

			@media screen and (min-width: 769px) {
				top: 20px;
			}

		}

		.navbar-header {
			@media screen and (min-width: 769px) {
				margin-top: 15px;
			}
		}
	}

	#body {
		header {
			margin-bottom: 35px;
			@media screen and (min-width: 769px) {
				margin-bottom: 60px;
			}
			>div {

				padding-bottom: 20px;
				padding-top: 120px;
				@media screen and (min-width: 769px) {
					padding-top: 160px;
					padding-bottom: 35px;
				}

				p,h2 {
					color: @white;
				}

				h2 {
					span {
						display: inline;
						background-color: @logoorange;
						border-radius: 7px;
						padding-left: 10px;
						padding-right: 10px;
					}

					font-size: 30px;
					line-height: 5rem;
					@media screen and (min-width: 769px) {
						font-size: 42px;
						line-height: 7rem;
					}
				}

				p {
					font-size: 18px;
					@media screen and (min-width: 769px) {
						font-size: 24px;
					}
					margin-bottom: 2.6rem;

					&.bold {
						text-align: center;;
						span a {
							border-bottom: 3px solid @white;
							letter-spacing: 4px;
							font-weight: 700;
							color: @white;
							text-decoration: none;
						}

						@media screen and (min-width: 769px) {
							text-align: right;
						}
					}
				}

			}
		}

	}

	.featured-stories {
		margin-bottom: 20px;
		.heading {
			margin-bottom: 25px;
			line-height: 1.5;
			font-size: 24px;
			font-weight: 700;
			@media screen and (min-width: 769px) {
				margin-bottom: 60px;
				font-size: 32px;
			}
			span {
				border-bottom: 3px solid @logoorange;
			}
		}

		article {
			margin-bottom: 60px;
			@media screen and (min-width: 769px) {
				padding: 0 20px;
			}

			&.logoorange {
				h3, .icomoon, p.more a {
					color: @logoorange;
				}
			}

			&.logored {
				h3, .icomoon, p.more a {
					color: @logored;
				}
			}

			&.purple {
				h3, .icomoon, p.more a {
					color: @purple;
				}
			}

			h3 {
				font-size: 28px;
				text-align: center;
				margin-bottom: 20px;
				@media screen and (min-width: 769px) {
					margin-bottom: 30px;
					font-size: 40px;
				}
			}


			.icomoon {
				font-size: 70px;
			}


			p {
				font-size: 18px;
				margin-bottom: 20px;

				&.more {
					text-align: center;
					a {
						font-weight: 700;
						font-size: 24px;
					}
				}
			}
		}
	}

	blockquote {
		background-color: @logored;
		padding: 30px 50px 50px 50px;
		margin-bottom: 60px;
		border-left: none;

		@media screen and (min-width: 769px) {
			padding: 90px 80px;
		}

		* {
			color: @white;
		}

		.container-fluid {
			position: relative;;
		}

		.fcyo-icon-left-quote, .fcyo-icon-right-quote {
			font-size: 25px;
			color: @logoorange;
			position: absolute;
			@media screen and (min-width: 769px) {
				font-size: 40px;
			}
		}

		.fcyo-icon-left-quote {
			left: 0;
			top: -40px;
			@media screen and (min-width: 769px) {
					left: -10px;
				top: -20px;
			}
		}

		.fcyo-icon-right-quote {
			right: 0;
			bottom: -30px;
			@media screen and (min-width: 769px) {
				right: 10px;
				bottom: -20px;
			}
		}

		figure {
			text-align: center;
			margin-bottom: 40px;
			max-width: 100%;

			img {
				max-width: 100%;
				max-width: 130px;
			}

			@media screen and (min-width: 769px) {
				text-align: initial;
				position: absolute;
				left: 90px;
				top: 0;
				width: 130px;

			}
		}

		.quote {
			padding-left: 0;
			font-size: 20px;
			margin-bottom: 2rem;
			position: relative;
			@media screen and (min-width: 769px) {
				font-size: 32px;
				padding-left: 230px;
				padding-right: 70px;
			}

		}

		.cite {
			text-align: right;
			font-size: 16px;
			@media screen and (min-width: 769px) {
				font-size: 18px;
				padding-right: 70px;
			}
		}
	}

	.news.indexbody {
		max-width: 1300px;
		@media screen and (min-width: 769px) {
			margin-bottom: 50px
		}
	}

	.about-contact-form {
		@media screen and (max-width: 768px) {
			padding-left: 0;
			padding-right: 0;
		}

		.content > div {
			padding: 40px;
			@media screen and (min-width: 769px) {
				padding: 80px;
			}

			h3 {
				color: @white;
				font-size: 28px;
				margin-bottom: 30px;
				@media screen and (min-width: 769px) {
						font-size: 48px;
				}
			}

			h4{
				color: @white;
				font-size: 18px;
				line-height: 1.5;
				@media screen and (min-width: 769px) {
					font-size: 32px;
				}
			}
		}
		.form {
			background-color: @logoorange;

			form {
				padding: 20px;

				@media screen and (min-width: 769px) {
					padding: 50px;
				}
			}
		}

		.btn {
			border: 3px solid @white;
	        text-transform: uppercase;
	        letter-spacing: .07em;
	        font-size: 1.4em;
	        font-weight: 600;
	        transition: all .3s;
	        padding: 12px 50px;
			background-color: transparent;
			color: @white;
			&:hover {
				background-color: @white;
				color: @logoorange;
			}
		}
	}

	#footer {
		padding: 40px 0 20px 0;

		@media screen and (max-width: 768px) {
			margin-top: 0;
		}

		.logo {
			margin-bottom: 30px;
		}

		ul {
			float: none;
			margin-bottom: 15px;

			@media screen and (max-width: 768px) {
				display: none;
			}
			text-align: center;
			li {
				float: none;
				display: inline-block;
				font-size: 20px;
				a {
					background-color: transparent;
				}
			}
		}
		.home {
			margin-bottom: 30px;

			a {
				font-size: 20px;
				color: @logoorange;
			}
		}
		.copy {
			color: #b5b5b5;
			font-size: 16px;
		}
	}

	#flash-messages {
		position: fixed;
		max-width: 280px;
		z-index: 100;
		bottom: 15px;
		right: 15px;
	}
}

/* Site basics and mixins across Site and Maps */
@import 'variables.less';

* {
	box-sizing: border-box;
}

body {
	font-family: @body;
}

h1, h2, h3, h4, h5, h6 {
	font-family: @body;	
}

.container-fluid {
	max-width: 1100px;
	margin: 0 auto;
}

input:focus, textarea:focus, button:focus {
    outline: none;
}

a {
	color: @logored;

	&:hover {
		color: darken(@logored, 20%);
	}
}

p, ul, ul li, ol, ol li {
	font-weight: 400;
	font-size: 100%;
}

p {
	strong {
		font-weight: 600;		
	}
}

.dark {
	background-color: darken(@black, 10%);
	color: white;	
}
